import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { fetchStateRegulations } from '../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const formatCurrency = (amount) => {
  if (amount == null) return "";
  return `$${parseFloat(amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const LiveStates = () => {
  const [stateRegulations, setStateRegulations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchStateRegulations();
        const sortedData = Object.entries(data).sort((a, b) => a[0].localeCompare(b[0]));
        setStateRegulations(sortedData);
      } catch (error) {
        logErrorToConsole("Error fetching state regulations", error);
        logErrorToSentryWithContext(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading || !stateRegulations) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="page-title">Live States</div>
      <hr className='my-2 line-divider'/>
      <div className="live-states-wrapper">
        <div className="mt-6 live-states-container">
          <table className="live-states-table">
            <thead>
              <tr>
                <th>State</th>
                <th>Live</th>
                <th>Min. Loan Amount</th>
                <th>Max. Loan Amount</th>
                <th>Max. Origination Fee</th>
              </tr>
            </thead>
            <tbody>
              {stateRegulations.map(([state, data]) => (
                <tr key={state} className={data.is_live ? 'live' : 'not-live'}>
                  <td>{state}</td>
                  <td>{data.is_live ? 'Yes' : 'No'}</td>
                  <td>{data.minimum_tuition_financed ? formatCurrency(data.minimum_tuition_financed) : ''}</td>
                  <td>{data.maximum_loan_amount ? formatCurrency(data.maximum_loan_amount) : ''}</td>
                  <td>{data.maximum_origination_fee ? formatCurrency(data.maximum_origination_fee) : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LiveStates;
