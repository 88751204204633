import React, { useState } from 'react';
import { Dialog, Button, TextField, Alert, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { certifyLoanApplication, getMonthlyPayment, assessRiskOnLoanApplication } from '../../../api/applications';
import { logErrorToConsole, logErrorToSentryWithContext } from '../../../utils/errorLogging';

import FortifyRatingModal from './FortifyRatingModal';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatInitialAmount = (amount) => {
  if (!amount) return "0.00";
  return parseFloat(amount).toFixed(2);
};

const formatCurrency = (amount) => {
  if (amount == null) return "";
  return `${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const TIER_TO_PERCENTAGE = {
  1: 0.05,
  2: 0.10,
  3: 0.15,
  4: 0.20
};

function calculateFirstPaymentDate(startDate) {
  const startDateObj = dayjs(startDate).tz('America/Chicago').startOf('day');
  const nowDateObj = dayjs().tz('America/Chicago');

  let repaymentStartObj = startDateObj.isAfter(nowDateObj) ? startDateObj : nowDateObj;
  repaymentStartObj = repaymentStartObj.add(30, 'days').startOf('day');

  while (repaymentStartObj.date() > 28) {
    repaymentStartObj = repaymentStartObj.add(1, 'day');
  }

  return repaymentStartObj;
}

const CertificationModal = ({ open, onClose, applicationData, onActionSuccess, activeSchool }) => {
  const [fortifyTier, setFortifyTier] = useState(applicationData.student.fortifyTier);
  const [fortifyScore, setFortifyScore] = useState(applicationData.student.fortifyScore);

  const [isEditingTuitionOutside, setIsEditingTuitionOutside] = useState(false);
  const [tuitionOutside, setTuitionOutside] = useState(
    formatInitialAmount(applicationData.program.tuition - parseFloat(applicationData.tuitionRequest))
  );

  const [isEditingDownPayment, setIsEditingDownPayment] = useState(false);
  const [downPayment, setDownPayment] = useState(
    formatInitialAmount(applicationData.loan.requiredDownPayment)
  );

  const [lastTuitionFinancedForPaymentCalc, setLastTuitionFinancedForPaymentCalc] = useState(
    applicationData.tuitionRequest - (
      !applicationData.loan.requiredDownPayment ? 0 : parseFloat(applicationData.loan.requiredDownPayment)
    )
  );

  const [monthlyPayment, setMonthlyPayment] = useState(!applicationData.loan.monthlyPayment ? null : parseFloat(applicationData.loan.monthlyPayment));
  const [loanTerm, setLoanTerm] = useState(applicationData.loan.loanTerm);

  const [isPaymentTermsLoading, setIsPaymentTermsLoading] = useState(false);
  const [paymentTermsError, setPaymentTermsError] = useState(null);

  const [startDate, setStartDate] = useState(applicationData.startDate);
  const [gradDate, setGradDate] = useState(applicationData.gradDate);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [isRiskAssessmentLoading, setIsRiskAssessmentLoading] = useState(false);
  const [zeroDownPaymentConfirmed, setZeroDownPaymentConfirmed] = useState(false);
  const [tuitionCollectedOutsideConfirmed, setTuitionCollectedOutsideConfirmed] = useState(false);

  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);

  const handleTuitionOutsideChange = (e) => {
    setError('');
    const value = e.target.value;
    setTuitionOutside(value === '' ? '0' : value);
    setZeroDownPaymentConfirmed(false);
  };

  const handleToggleEditTuitionOutside = () => {
    if (isEditingTuitionOutside) {
      const numericValue = parseFloat(tuitionOutside);
      setTuitionOutside(isNaN(numericValue) ? "0" : numericValue.toString());
      fetchMonthlyPayment();
    } else {
      setIsEditingTuitionOutside(true);
    }
  };

  const handleToggleEditDownPayment = () => {
    if (isEditingDownPayment) {
      const numericValue = parseFloat(downPayment);
      setDownPayment(isNaN(numericValue) ? "0" : numericValue.toString());
      fetchMonthlyPayment();
    } else {
      setIsEditingDownPayment(true);
    }
  };

  const handleDownPaymentChange = async (e) => {
    setError('');
    const value = e.target.value;
    setDownPayment(value === '' ? '0' : value);
    setZeroDownPaymentConfirmed(false);
  };

  // TODO: i would love to trigger a fetchMonthlyPayment here, but can't figure it out.
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleRiskAssessmentClick = async () => {
    setIsRiskAssessmentLoading(true);
    setError('');
    try {
      const response = await assessRiskOnLoanApplication(applicationData.schoolId, applicationData.id);
      if (response.error) {
        setError(response.error);
      } else {
        setFortifyScore(response.fortify_score);
        setFortifyTier(response.fortify_tier);
      }
    } catch (error) {
      logErrorToConsole("Error performing risk assessment", error);
      setError(error.response?.data?.error || 'Failed to perform risk assessment');
    } finally {
      setIsRiskAssessmentLoading(false);
    }
  };

  const fetchMonthlyPayment = async () => {
    setIsEditingTuitionOutside(false);
    setIsEditingDownPayment(false);
    setIsPaymentTermsLoading(true);
    setPaymentTermsError(null);
    setLastTuitionFinancedForPaymentCalc(tuitionFinanced);

    try {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const tuitionRequest = parseFloat(tuitionFinanced) + parseFloat(downPayment);
      const response = await getMonthlyPayment(applicationData.schoolId, applicationData.id, tuitionRequest, parseFloat(downPayment), formattedStartDate);
      setMonthlyPayment(parseFloat(response.monthly_payment));
      setLoanTerm(response.loan_term);
    } catch (error) {
      logErrorToConsole("Failed to fetch monthly payment", error);
      setPaymentTermsError(error.response?.data?.error ? `Error: ${error.response?.data?.error}` : 'Please choose a valid tuition request & down payment to see the monthly payment.')

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionFinanced: tuitionFinanced,
          downPayment: parseFloat(downPayment),
          startDate,
        });
      }
    } finally {
      setIsPaymentTermsLoading(false);
    }
  };

  const handleCertify = async () => {
    setIsLoading(true);
    setError('');
    try {
      const tuitionRequest = parseFloat(tuitionFinanced) + parseFloat(downPayment);
      await certifyLoanApplication(applicationData.schoolId, applicationData.id, {
        decision: "approved",
        tuition_request: tuitionRequest,
        down_payment: parseFloat(downPayment),
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        grad_date: dayjs(gradDate).format('YYYY-MM-DD'),
      });
      onClose();
      onActionSuccess();
    } catch (error) {
      logErrorToConsole("Error certifying loan application", error);
      setError(error.response?.data?.error || 'Failed to certify loan application');

      if (
        error.response?.status === 400 &&
        !!error.response?.data?.error
      ) {
        // don't raise an alert
      } else {
        logErrorToSentryWithContext(error, {
          schoolId: applicationData.schoolId,
          applicationId: applicationData.id,
          tuitionFinanced: tuitionFinanced,
          downPayment: parseFloat(downPayment),
          startDate,
          gradDate
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const tuitionFinanced = applicationData.program.tuition - parseFloat(tuitionOutside) - parseFloat(downPayment);

  const renderTuitionBreakdown = () => (
    <div className="mb-6">
      <h1 className="text-lg font-semibold">Tuition Breakdown</h1>
      <div className="flex justify-between items-center mb-4">
        <p className="mt-4 text-[15px]"><b>Total program cost</b></p>
        <div className="flex items-center">
          <img className="w-7 h-5 mr-2" src="/certificationModal/dollar.svg" alt="$" />
          <div className="min-w-[75px] text-right">{formatCurrency(applicationData.program.tuition)}</div>
          <div className="w-9"></div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <p className="text-[15px]">Tuition <u>already collected</u>, or discounts:</p>
        <div className="flex items-center">
          <img className="w-7 h-5 mr-2" src="/certificationModal/dollar_with_negative_sign.svg" alt="-$" />
          {isEditingTuitionOutside ? (
            <TextField
              className="max-w-[100px]"
              value={tuitionOutside}
              onChange={handleTuitionOutsideChange}
              size="small"
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ 
                style: { 
                  borderRadius: '8px',
                  borderColor: '#b91c1c'
                } 
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#b91c1c',
                  },
                  '&:hover fieldset': {
                    borderColor: '#b91c1c',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#b91c1c',
                  }
                }
              }}
            />
          ) : (
            <div className="min-w-[75px] text-right">{formatCurrency(parseFloat(tuitionOutside))}</div>
          )}
          <div onClick={handleToggleEditTuitionOutside}>
            <img
              className="ml-4 w-5 h-5"
              src={isEditingTuitionOutside ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
              alt="Edit Icon"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <p className="text-[15px]">Down payment to collect through Fortify:</p>
        <div className="flex items-center">
          <img className="w-7 h-5 mr-2" src="/certificationModal/dollar_with_negative_sign.svg" alt="-$" />
          {isEditingDownPayment ? (
            <TextField
              className="max-w-[100px]"
              value={downPayment}
              onChange={handleDownPaymentChange}
              size="small"
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ 
                style: { 
                  borderRadius: '8px',
                  borderColor: '#b91c1c'
                } 
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#b91c1c',
                  },
                  '&:hover fieldset': {
                    borderColor: '#b91c1c',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#b91c1c',
                  }
                }
              }}
            />
          ) : (
            <div className="min-w-[75px] text-right">{formatCurrency(parseFloat(downPayment))}</div>
          )}
          <div onClick={handleToggleEditDownPayment}>
            <img
              className="ml-4 w-5 h-5"
              src={isEditingDownPayment ? "/certificationModal/save.svg" : "/certificationModal/edit.svg"}
              alt="Edit Icon"
            />
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <div className="flex justify-between items-center">
        <p className="text-[15px] font-semibold">Tuition financed by the Fortify loan:</p>
        <div className="flex items-center space-x-4">
          <p className="text-base font-semibold">{formatCurrency(tuitionFinanced)}</p>
          <div className="w-5 h-5"></div>
        </div>
      </div>
    </div>
  );

  const shouldShowZeroDownPaymentWarning = () => {
    return applicationData.program.displayWarningForCertificationsWithZeroDownPayment && parseFloat(downPayment) === 0;
  };

  const shouldShowTuitionCollectedSeparatelyWarning = () => {
    return parseFloat(tuitionOutside) !== 0;
  };

  const confirmationBoxesCheckedForCertification = () => {
    if (shouldShowZeroDownPaymentWarning() && !zeroDownPaymentConfirmed) {
      return false;
    }

    if (shouldShowTuitionCollectedSeparatelyWarning() && !tuitionCollectedOutsideConfirmed) {
      return false;
    }

    return true;
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { borderRadius: '26px' } }}>
      <div className='px-12 py-12'>
        <div>
          <h1 className="text-xl mb-8"><b>Certify {applicationData.student.account.firstName} {applicationData.student.account.lastName}'s application</b></h1>

          <div className="mb-6">
            <h1 className="text-lg mb-2 font-semibold">Program: {applicationData.program.programName}</h1>
            <p className="text-gray-700 text-[13px]">Need to change {applicationData.student.account.firstName}'s program? Just <a href="/report-a-bug" className="underline" target="_blank" rel="noopener noreferrer">let us know</a>.</p>
          </div>

          <div className="mb-6">
            <h1 className="text-lg mb-4 font-semibold">Enrollment Dates</h1>
            <div className="flex justify-between space-x-8">
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="medium"
                InputProps={{ style: { borderRadius: '8px', height: '48px' } }}
              />
              <TextField
                label="Grad Date"
                type="date"
                value={gradDate}
                onChange={(e) => setGradDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="medium"
                InputProps={{ style: { borderRadius: '8px', height: '48px' } }}
              />
            </div>
            {activeSchool?.should_show_repayment_period_at_cert && (
              <p className="text-gray-700 text-[13px] mt-2">
                The first payment will be due on approximately {calculateFirstPaymentDate(startDate).tz('America/Chicago').format('MMM D, YYYY')}.
              </p>
            )}
          </div>

          {renderTuitionBreakdown()}

          <div className="mb-8 py-6 px-6 mx-auto border-[1px] border-fortify-green/60 rounded-lg bg-fortify-green bg-opacity-5">
            {isPaymentTermsLoading ? (
              <div className="flex justify-center items-center py-4 px-4 h-full">
                <CircularProgress size={15}/>
              </div>
            ) : (
              <>
                {paymentTermsError ? (
                  <p className="text-fortify-green font-medium">{paymentTermsError}</p>
                ) : (
                  <p className="text-fortify-green font-medium">
                    Based on ${formatCurrency(lastTuitionFinancedForPaymentCalc || 0)} of tuition financed by a Fortify loan, {applicationData.student.account.firstName}'s payments will be <strong>${formatCurrency(monthlyPayment)}</strong>/month for <strong>{loanTerm} months</strong>.
                  </p>
                )}
              </>
            )}
          </div>

          <div className="">
            <h1 className="text-lg mb-2 font-semibold">Risk Assessment</h1>

            <p className="text-blue-700 mb-4 text-[14px]">
              Assessing risk on your students with Fortify is now <b>free until February 15th</b> - a value of $10 per student. Thank you for being a beta user!
            </p>

            {fortifyTier && fortifyScore && (
              <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                {fortifyTier === -1 ? (
                  <p>We weren't able to assess the risk on this student, because their credit file was frozen. <a href="/report-a-bug" className="underline" target="_blank" rel="noopener noreferrer">Reach out for help</a>.</p>
                ) : (
                  <>
                    <div className="flex items-center justify-between mb-1">
                      <p><span className="font-semibold">Fortify Tier:</span> {fortifyTier}</p>
                      <p><span className="font-semibold">Fortify Score:</span> {fortifyScore}</p>
                      <button 
                        onClick={() => setIsRatingModalOpen(true)}
                        className="text-sm text-legal-gray underline hover:text-[#00684a]"
                      >
                        What does this mean?
                      </button>
                    </div>
                    <div>
                      <p className="mt-3">
                        <span className="font-semibold">Suggested down payment:</span> ${formatCurrency(applicationData.program.tuition * TIER_TO_PERCENTAGE[fortifyTier])} ({TIER_TO_PERCENTAGE[fortifyTier] * 100}% of tuition)
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}

            {(!fortifyTier || !fortifyScore) && 
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                onClick={handleRiskAssessmentClick}
                disabled={isRiskAssessmentLoading}
                sx={{
                  mb: 3,
                  position: 'relative',
                  overflow: 'hidden',
                  color: 'fortify-green',
                  borderColor: 'fortify-green',
                  backgroundColor: 'white',
                  '&:hover': {
                    borderColor: 'fortify-green',
                    backgroundColor: 'white',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 104, 74, 0.20)',
                      animation: 'fillFromLeft 0.65s forwards',
                    }
                  },
                  '@keyframes fillFromLeft': {
                    '0%': { transform: 'translateX(-100%)' },
                    '100%': { transform: 'translateX(0)' }
                  },
                  height: '48px',
                }}
              >
                {isRiskAssessmentLoading ? (
                  <CircularProgress size={16} style={{ color: '#00684a' }} />
                ) : (
                  'Perform Risk Assessment'
                )}
              </Button>
            }

            <FortifyRatingModal
              open={isRatingModalOpen}
              onClose={() => setIsRatingModalOpen(false)}
            />
          </div>
          
          {error && <Alert severity="error" className="mb-4">{error}</Alert>}
          
          {shouldShowZeroDownPaymentWarning() && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={zeroDownPaymentConfirmed}
                  onChange={(e) => setZeroDownPaymentConfirmed(e.target.checked)}
                />
              }
              label="I confirm that I want to certify this student without any down payment collected through Fortify."
              className="mt-4 mb-6"
            />
          )}

          {shouldShowTuitionCollectedSeparatelyWarning() && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={tuitionCollectedOutsideConfirmed}
                  onChange={(e) => setTuitionCollectedOutsideConfirmed(e.target.checked)}
                />
              }
              label={`I confirm that $${formatCurrency(parseFloat(tuitionOutside))} of this student's total tuition will be collected outside of Fortify.`}
              className="mb-6"
            />
          )}

          {(!confirmationBoxesCheckedForCertification() || isEditingDownPayment || isEditingTuitionOutside) && (
            <p className='text-red-700 text-sm mb-6 text-right'>
              {isEditingDownPayment || isEditingTuitionOutside 
                ? 'Please click the save icon above to confirm your changes.'
                : 'Please check the confirmation box above to certify this application.'}
            </p>
          )}
          {(
            confirmationBoxesCheckedForCertification() && !isEditingDownPayment && !isEditingTuitionOutside && !fortifyScore && (
            <p className='text-orange-600 text-sm mb-6 text-right'>
              Before you certify this loan, we recommend you run a risk assessment.
              <br/>Knowing their financial risk profile can help maximize the tuition you collect.
            </p>
          ))}
          <div className="flex justify-center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div className="flex justify-between space-x-4 w-full">
                <Button 
                  className="flex-grow" 
                  variant="outlined" 
                  color="primary" 
                  onClick={onClose} 
                  sx={{ 
                    color: 'fortify-green', 
                    borderColor: 'fortify-green', 
                    textTransform: 'none', 
                    height: '48px', 
                    fontSize: '1rem', 
                    fontWeight: 'medium',
                    borderRadius: '10px'
                  }}
                >
                  Cancel
                </Button>
                <Button 
                  className="flex-grow"
                  variant="contained"
                  color="primary"
                  sx={{ 
                    backgroundColor: 'fortify-green', 
                    textTransform: 'none', 
                    height: '48px', 
                    fontSize: '1rem', 
                    fontWeight: 'medium',
                    borderRadius: '10px'
                  }}
                  onClick={handleCertify}
                  disabled={paymentTermsError || isEditingTuitionOutside || isEditingDownPayment || !confirmationBoxesCheckedForCertification()}
                >
                  Certify
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CertificationModal;