import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDisbursementsForSchool } from '../../api/applications';
import { DisbursementData, UpcomingDisbursementData } from '../../models/RepaymentData';
import { CircularProgress } from '@mui/material';
import { formatCurrency, formatCurrencyForCSV, formatDate, formatDateForCSV } from '../../utils/formatters';
import ExportToCSV from '../shared/ExportToCSV';

const Disbursements = ({ activeSchool }) => {
  const navigate = useNavigate();
  const [disbursementsData, setDisbursementsData] = useState([]);
  const [upcomingDisbursement, setUpcomingDisbursement] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await fetchDisbursementsForSchool(activeSchool.id);
      const formattedData = response.disbursements.map(item => new DisbursementData(item));
      const filteredData = formattedData.filter(item => item.amountDisbursed !== 0);
      const sortedData = filteredData.sort((a, b) => b.disbursementDate - a.disbursementDate);
      setDisbursementsData(sortedData);
      
      if (response.upcoming_disbursement) {
        setUpcomingDisbursement(new UpcomingDisbursementData(response.upcoming_disbursement));
      }
    } catch (error) {
      console.error("Error fetching disbursements data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!activeSchool.has_accounting_access) {
      navigate('/dashboard');
    } else {
      fetchData();
    }
  }, [activeSchool, navigate]);

  const handleReportDownload = (reportUrl) => {
    window.open(reportUrl, '_blank');
  };

  const exportToCSV = () => {
    const headers = ["Disbursement Date", "Amount Disbursed", "Full Disbursement Report"];
    const csvData = disbursementsData.map(item => [
      formatDateForCSV(item.disbursementDate),
      formatCurrencyForCSV(item.amountDisbursed),
      item.report
    ]);
    const csvContent = [headers, ...csvData].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `Fortify Disbursements.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  if (disbursementsData.length === 0 && upcomingDisbursement === null) {
    return (
      <div className="flex flex-col">
        <div className="page-title">Disbursements</div>
        <hr className='my-2 line-divider'/>
        <div className="flex mt-36 items-center justify-center">
          <div className="text-center">
            <div className="text-base empty-screen-message mt-4">
              You don't have any disbursements yet.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full overflow-hidden h-full">
      <div className="flex justify-between items-center mb-2">
        <div className="page-title">Disbursements</div>
        <ExportToCSV exportToCSV={exportToCSV} />
      </div>
      <hr className='mt-2 line-divider'/>
      <div className="flex-grow overflow-hidden loan-table-wrapper">
        <div className="mt-6 loan-table-container">
          <table className="loan-table">
            <thead className="bg-gray-100 sticky top-0">
              <tr className="h-12">
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Disbursement Date</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Amount Disbursed</th>
                <th className="py-2 px-4 border-b text-left whitespace-nowrap">Reports</th>
              </tr>
            </thead>
            <tbody>
              {upcomingDisbursement && (
                <tr className="border-b h-12 bg-gray-50/50">
                  <td className="py-2 px-4 whitespace-nowrap text-gray-400 italic">
                    {formatDate(upcomingDisbursement.nextDisbursementDate)} (upcoming)
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <div className="flex flex-col">
                      <span className="">
                        {formatCurrency(upcomingDisbursement.confirmedTuitionToBeDisbursed)}
                      </span>
                      {upcomingDisbursement.potentialTuitionReceivable > 0 && (
                        <span className="text-gray-400 text-sm italic">
                          + up to {formatCurrency(upcomingDisbursement.potentialTuitionReceivable)} from pending payments
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="py-2 px-4 whitespace-nowrap text-gray-400 italic">
                    Not yet available
                  </td>
                </tr>
              )}
              {disbursementsData.map((item, index) => (
                <tr key={index} className="border-b h-12">
                  <td className="py-2 px-4 whitespace-nowrap">{formatDate(item.disbursementDate)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">{formatCurrency(item.amountDisbursed)}</td>
                  <td className="py-2 px-4 whitespace-nowrap">
                    <button
                      onClick={() => handleReportDownload(item.report)}
                      className="flex items-center no-underline hover:underline"
                    >
                      Download report
                      <img src="/download_icon.svg" alt="Download" className="w-5 h-5 ml-2" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Disbursements;